// import Preview from "./PreviewText";
// import MainV1 from "./main/MainV1";
// import MainV2 from "./main/MainV2";
import MainV3 from "./main/MainV3";
// import MainV4 from "./main/MainV4";

function App() {
  const onClickSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    console.log({ data });
    await fetch("https://atech.kattari.in/contact-us", {
      method: "POST",
      body: data,
    });
    // window.location.reload(false);
  };
  return <MainV3 onClickSubmit={onClickSubmit} />;
  // return <Preview />;
}

export default App;
