import React from "react";
import Grid from "@mui/material/Grid";
// import TextField from "@mui/material/TextField";
// import Typography from "@mui/material/Typography";
// import MenuItem from "@mui/material/MenuItem";
// import Title from "../../title";
import TextPlayTitle from "../../text/TextPlayTitle";
import axios from "axios";

import "./style.css";

// const solutions = [
//   {
//     value: "ERP SOLUTION",
//     label: "ERP SOLUTION",
//   },
//   {
//     value: "Ecommerce Solution",
//     label: "Ecommerce Solution",
//   },
//   {
//     value: "Digital Transformation - Integrated ERP & Ecommerce Solution",
//     label: "Digital Transformation - Integrated ERP & Ecommerce Solution",
//   },
//   {
//     value: "Other Softwares",
//     label: "Other Softwares",
//   },
// ];

const Seperator = () => {
  return (
    <>
      <br />
    </>
  );
};
const defaultData = {
  name: "",
  company: "",
  email: "",
  phone: "",
  solution: "ecommerce",
};
export default function ContactUsV2() {
  const [data, setData] = React.useState(defaultData);
  const [message, setMessage] = React.useState(null);

  const onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log({ data });
    // const response = await fetch("https://atech.kattari.in/contact-us", {
    //   method: "POST",
    //   body: JSON.stringify(data),
    // });

    const response = await axios.post(
      "https://atech.kattari.in/contact-us",
      data
    );
    console.log(response.data);

    // window.location.reload(false);
    setMessage("Submitted Successfully");
    setData(defaultData);
  };

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      {/* <Title title={"Transform"} subtitle={"YOUR BUSINESS"} /> */}
      <TextPlayTitle />

      {message ? <p className="input-label">{message}</p> : null}

      <form className="input-form" onSubmit={onSubmit}>
        <label className="input-label" for="name">
          Full Name (required):
        </label>
        <input
          className="input-field"
          type="text"
          id="name"
          name="name"
          required
          minlength="4"
          placeholder="Your Name..."
          value={data.name}
          onChange={onChange}
        />

        <Seperator />

        <label className="input-label" for="name">
          Company (optional):
        </label>
        <input
          className="input-field"
          type="text"
          id="company"
          name="company"
          minlength="4"
          placeholder="Company Name..."
          value={data.company}
          onChange={onChange}
        />

        <Seperator />

        <label className="input-label" for="email">
          E-mail (required):
        </label>
        <input
          className="input-field"
          type="email"
          id="email"
          name="email"
          minlength="4"
          required
          placeholder="you@yourcompany.com"
          value={data.email}
          onChange={onChange}
        />

        <Seperator />

        <label className="input-label" for="phone">
          Phone (optional):
        </label>
        <input
          className="input-field"
          type="tel"
          id="phone"
          name="phone"
          minlength="4"
          required
          placeholder="+123-0123456789"
          value={data.phone}
          onChange={onChange}
        />

        <Seperator />

        <label className="input-label" for="solution">
          Solution :
        </label>
        <select
          className="input-select"
          id="solution"
          name="solution"
          value={data.solution}
          onChange={onChange}
        >
          <option value="erp">ERP Solution</option>
          <option value="ecommerce">eCommerce Solution</option>
          <option value="transformation">
            Digital Transformation - Integrated ERP & eCommerce Solution
          </option>
          <option value="others">Other Softwares Solutions</option>
        </select>

        <Seperator />

        <input className="input-submit" type="submit" value="Submit"></input>
      </form>
    </Grid>
  );
}
