import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";

import ContactUsV3 from "../../contact-us/ContactUsV3";

import "./style.css";

// const BG_COLOR1 = "#f2f2f2";
const BG_COLOR2 = "#B9C1CC";

export default function Main({ onClickSubmit }) {
  // const c = document.getElementById("page-bg-canvas");
  // const bgGraphics1 = () => {
  //   var c = document.getElementById("page-bg-canvas");
  //   var ctx = c.getContext("2d");
  //   var w = (c.width = window.innerWidth);
  //   var h = (c.height = window.innerHeight);
  //   var clearColor = "rgba(0, 0, 0, .1)";
  //   var max = 30;
  //   var drops = [];

  //   function random(min, max) {
  //     return Math.random() * (max - min) + min;
  //   }

  //   function O() {}

  //   O.prototype = {
  //     init: function () {
  //       this.x = random(0, w);
  //       this.y = 0;
  //       this.color = "hsl(180, 100%, 50%)";
  //       this.w = 2;
  //       this.h = 1;
  //       this.vy = random(4, 5);
  //       this.vw = 3;
  //       this.vh = 1;
  //       this.size = 2;
  //       this.hit = random(h * 0.8, h * 0.9);
  //       this.a = 1;
  //       this.va = 0.96;
  //     },
  //     draw: function () {
  //       if (this.y > this.hit) {
  //         ctx.beginPath();
  //         ctx.moveTo(this.x, this.y - this.h / 2);

  //         ctx.bezierCurveTo(
  //           this.x + this.w / 2,
  //           this.y - this.h / 2,
  //           this.x + this.w / 2,
  //           this.y + this.h / 2,
  //           this.x,
  //           this.y + this.h / 2
  //         );

  //         ctx.bezierCurveTo(
  //           this.x - this.w / 2,
  //           this.y + this.h / 2,
  //           this.x - this.w / 2,
  //           this.y - this.h / 2,
  //           this.x,
  //           this.y - this.h / 2
  //         );

  //         ctx.strokeStyle = "hsla(180, 100%, 50%, " + this.a + ")";
  //         ctx.stroke();
  //         ctx.closePath();
  //       } else {
  //         ctx.fillStyle = this.color;
  //         ctx.fillRect(this.x, this.y, this.size, this.size * 5);
  //       }
  //       this.update();
  //     },
  //     update: function () {
  //       if (this.y < this.hit) {
  //         this.y += this.vy;
  //       } else {
  //         if (this.a > 0.03) {
  //           this.w += this.vw;
  //           this.h += this.vh;
  //           if (this.w > 100) {
  //             this.a *= this.va;
  //             this.vw *= 0.98;
  //             this.vh *= 0.98;
  //           }
  //         } else {
  //           this.init();
  //         }
  //       }
  //     },
  //   };

  //   function resize() {
  //     w = c.width = window.innerWidth;
  //     h = c.height = window.innerHeight;
  //   }

  //   function setup() {
  //     for (var i = 0; i < max; i++) {
  //       (function (j) {
  //         setTimeout(function () {
  //           var o = new O();
  //           o.init();
  //           drops.push(o);
  //         }, j * 200);
  //       })(i);
  //     }
  //   }

  //   function anim() {
  //     ctx.fillStyle = clearColor;
  //     ctx.fillRect(0, 0, w, h);
  //     for (var i in drops) {
  //       drops[i].draw();
  //     }
  //     requestAnimationFrame(anim);
  //   }

  //   setup();
  //   anim();
  // };

  const bgGraphics2 = () => {
    const c = document.getElementById("page-bg-canvas");
    let $ = c.getContext("2d");
    c.width = window.innerWidth;
    c.height = window.innerHeight;
    $.fillStyle = "hsla(0,0%,95%,1)";
    $.fillRect(0, 0, c.width, c.height);

    let t = 0,
      fr = 0;
    let str, d, l, i, e, p;

    function draw() {
      if (!t) {
        t = 0;
        fr = 0;
        $.translate(c.width / 2, c.height / 2);
        str = [{ x: 0, y: 0, a: 0, ab: 0, w: 8, p: [], l: 9.6e1 * 7e1 }];
      }
      d = Date.now();
      t = Math.random() * d;
      fr++;
      window.requestAnimationFrame(draw);
      str = str.filter((s) => s.l--);
      str.forEach((s) => {
        var dx = Math.cos(s.a) * s.w;
        var dy = Math.sin(s.a) * s.w;
        s.x += dx;
        s.y += dy;
        s.a += s.ab / s.w;
        s.p.splice(0, s.p.length - 3e1);
        s.p.push({ x: s.x, y: s.y, dx: dx, dy: dy });
        if (fr % 3e1 == 0) {
          s.ab = Math.random() - 5e-1;
          s.ab *= 6;
        }
        if (s.w > 1 && Math.random() < s.l / 1.6834e4 - 2.6e-2) {
          str.push({
            x: s.x,
            y: s.y,
            a: s.a,
            ab: s.ab,
            w: s.w,
            p: [],
            l: (Math.min * s.w) | (s.w * 3.2e1 * (1 + Math.random())),
          });
        }
      });
      str.forEach((s) => {
        $.strokeStyle = "hsla(0,0%," + Math.random() * 2e1 + "%, .03)";
        $.beginPath();
        l = s.p.length - 1;
        for (i = l; (p = s.p[i]); i -= 8) {
          e = (i / l) * 8;
          $.moveTo(p.x, p.y);
          $.lineTo(p.x - e * p.dx, p.y - e * p.dy);
        }
        $.stroke();
      });
    }
    draw();
  };

  // const bgGraphics3 = () => {
  //   const c = document.getElementById("page-bg-canvas");
  //   c.width = window.innerWidth;
  //   c.height = window.innerHeight;
  //   let $ = c.getContext("2d");
  //   var msX = 0;
  //   var msY = 0;
  //   var _x = 0;
  //   var _y = 0;
  //   var u = 0;

  //   let i;

  //   window.addEventListener(
  //     "touchmove",
  //     function (e) {
  //       _x = e.touches[0].pageX;
  //       _y = e.touches[0].pageY;

  //       if (_x < 0) {
  //         _x = 0;
  //       }
  //       if (_y < 0) {
  //         _y = 0;
  //       }

  //       msX = _x;
  //       msY = _y;
  //       return true;
  //     },
  //     false
  //   );

  //   window.addEventListener(
  //     "mousemove",
  //     function (e) {
  //       _x = e.pageX;
  //       _y = e.pageY;

  //       if (_x < 0) {
  //         _x = 0;
  //       }
  //       if (_y < 0) {
  //         _y = 0;
  //       }

  //       msX = _x;
  //       msY = _y;
  //       return true;
  //     },
  //     false
  //   );

  //   var _tick = 0;
  //   var _rotX = 0;
  //   var _rotY = 0;
  //   var _sp = 0.08;
  //   var _sc = 0.3;
  //   var _obj = [];
  //   var g;

  //   //primary center [ obj 0 ]
  //   _obj.push({
  //     name: "primary",
  //     x: window.innerWidth / 2,
  //     y: window.innerHeight / 2,
  //     z: 0,
  //     prime: null,
  //     sc: 1,
  //     rad: 180,
  //     rotX: 0,
  //     rotY: 0,
  //     n: _tick++,
  //   });

  //   //tiny inner orbiter [ obj 1 ]
  //   _obj.push({
  //     name: "orbital",
  //     x: 0,
  //     y: 0,
  //     z: 0,
  //     rad: 15,
  //     prime: _obj[0],
  //     dist: 40,
  //     rotX: ((Math.PI * 2) / 7) * 0,
  //     rotY: 0,
  //     spX: 365 / 88,
  //     spY: 0,
  //     n: _tick++,
  //   });

  //   //tiny inner orbiter [ obj 2 ]
  //   _obj.push({
  //     name: "orbital",
  //     x: 0,
  //     y: 0,
  //     z: 0,
  //     rad: 25,
  //     prime: _obj[0],
  //     dist: 60,
  //     rotX: ((Math.PI * 2) / 7) * 1,
  //     rotY: 0,
  //     spX: 365 / 365,
  //     spY: 0,
  //     n: _tick++,
  //   });

  //   //tiny inner orbiter [ obj 3 ]
  //   _obj.push({
  //     name: "orbital",
  //     x: 0,
  //     y: 0,
  //     z: 0,
  //     rad: 25,
  //     prime: _obj[0],
  //     dist: 110,
  //     rotX: ((Math.PI * 2) / 7) * 2,
  //     rotY: 0,
  //     spX: 365 / 203,
  //     spY: 0,
  //     n: _tick++,
  //   });

  //   //tiny inner orbiter [ obj 4 ]
  //   _obj.push({
  //     name: "orbital",
  //     x: 0,
  //     y: 0,
  //     z: 0,
  //     rad: 35,
  //     prime: _obj[0],
  //     dist: 150,
  //     rotX: ((Math.PI * 2) / 7) * 3,
  //     rotY: 0,
  //     spX: 365 / 687,
  //     spY: 0,
  //     n: _tick++,
  //   });
  //   // [ obj 5 ]
  //   _obj.push({
  //     name: "orbital",
  //     x: 0,
  //     y: 0,
  //     z: 0,
  //     rad: 60,
  //     prime: _obj[0],
  //     dist: 190,
  //     rotX: ((Math.PI * 2) / 7) * 4,
  //     rotY: 0,
  //     spX: 1 / 11.86,
  //     spY: 0,
  //     n: _tick++,
  //   });

  //   // [ obj 6 ]
  //   _obj.push({
  //     name: "orbital",
  //     x: 0,
  //     y: 0,
  //     z: 0,
  //     rad: 90,
  //     prime: _obj[0],
  //     dist: 440,
  //     rotX: ((Math.PI * 2) / 7) * 5,
  //     rotY: 0,
  //     spX: 29.46 / 365,
  //     spY: 0,
  //     n: _tick++,
  //   });

  //   //[ obj 7 ]
  //   _obj.push({
  //     name: "orbital",
  //     x: 0,
  //     y: 0,
  //     z: 0,
  //     rad: 130,
  //     prime: _obj[0],
  //     dist: 600,
  //     rotX: ((Math.PI * 2) / 7) * 6,
  //     rotY: 0,
  //     spX: 1 / 84.07,
  //     spY: 0,
  //     n: _tick++,
  //   });

  //   // [ obj 8 ]
  //   _obj.push({
  //     name: "orbital",
  //     x: 0,
  //     y: 0,
  //     z: 0,
  //     rad: 60,
  //     prime: _obj[0],
  //     dist: 780,
  //     rotX: ((Math.PI * 2) / 7) * 7,
  //     rotY: 0,
  //     spX: 1 / 164.8,
  //     spY: 0,
  //     n: _tick++,
  //   });

  //   //tiny orbiter of obj 3
  //   _obj.push({
  //     name: "orbital",
  //     x: 0,
  //     y: 0,
  //     z: 0,
  //     rad: 8,
  //     prime: _obj[3],
  //     dist: 30,
  //     rotX: ((Math.PI * 2) / 7) * 1,
  //     rotY: 0,
  //     spX: 365 / 97,
  //     spY: 0,
  //     n: _tick++,
  //   });

  //   //obj 7 ring
  //   for (i = 0; i < 25; i++) {
  //     _obj.push({
  //       x: 0,
  //       y: 0,
  //       z: 0,
  //       rad: Math.random() * 8,
  //       prime: _obj[7],
  //       dist: 40 + 10 * Math.random(),
  //       col: "hsla(255, 255%, 255%, 1)",
  //       rotX: Math.PI * 2 * Math.random(),
  //       rotY: 0.1 * Math.random(),
  //       spX: 0.1 + 0.1 * Math.random(),
  //       spY: 0.0 * Math.random(),
  //       n: _tick++,
  //     });
  //   }

  //   //obj 6 ring
  //   for (i = 0; i < 50; i++) {
  //     _obj.push({
  //       x: 0,
  //       y: 0,
  //       z: 0,
  //       rad: 3,
  //       prime: _obj[6],
  //       dist: 20 + 5 * Math.random(),
  //       col: "hsla(40, 95%, 65%, 1)",
  //       rotX: Math.PI * 2 * (i / 69),
  //       rotY: 0,
  //       spX: 0.2 + 0.4 * Math.random(),
  //       spY: 0 * Math.random(),
  //       n: _tick++,
  //     });
  //   }
  //   for (i = 0; i < 50; i++) {
  //     _obj.push({
  //       x: 0,
  //       y: 0,
  //       z: 0,
  //       rad: 3,
  //       prime: _obj[6],
  //       dist: 20 + 5 * Math.random(),
  //       col: "hsla(40, 95%, 95%, 1)",
  //       rotX: 0,
  //       rotY: Math.PI * 2 * (i / 69),
  //       spX: 0.2 + 0.4 * Math.random(),
  //       spY: 0 * Math.random(),
  //       n: _tick++,
  //     });
  //   }
  //   //innermost ring around center [ obj 0 ]
  //   for (i = 0; i < 30; i++) {
  //     _obj.push({
  //       x: 0,
  //       y: 0,
  //       z: 0,
  //       rad: 10,
  //       prime: _obj[0],
  //       dist: 30 + 20 * Math.random(),
  //       col:
  //         "rgb(" +
  //         Math.floor(Math.random() * 255) +
  //         "," +
  //         Math.floor(Math.random() * 130) +
  //         "," +
  //         Math.floor(Math.random() * 255) +
  //         ")",
  //       rotX: Math.PI * 2 * Math.random(),
  //       rotY: 0.1 * Math.random(),
  //       spX: 0.1 + 0.1 * Math.random(),
  //       spY: 0.0 * Math.random(),
  //       n: _tick++,
  //     });
  //   }
  //   //outer sphere
  //   for (i = 0; i < 1400; i++) {
  //     _obj.push({
  //       x: 0,
  //       y: 0,
  //       z: 0,
  //       rad: Math.random() * 12,
  //       prime: _obj[0],
  //       dist: 80 + 1300 * Math.random(),
  //       col:
  //         "rgb(" +
  //         Math.floor(Math.random() * 255) +
  //         "," +
  //         Math.floor(Math.random() * 130) +
  //         "," +
  //         Math.floor(Math.random() * 255) +
  //         ")",
  //       rotX: Math.PI * 2 * Math.random(),
  //       rotY: Math.random() * 0.4,
  //       spX: 0.05 + 0.2 - 0.2 * Math.random(),
  //       spY: 0.001 * Math.random(),
  //       n: _tick++,
  //     });
  //   }

  //   function orbit() {
  //     $.fillStyle = "hsla(247, 15%, 5%, .95)";
  //     $.fillRect(0, 0, c.width, c.height);
  //     $.fill();
  //     calc();
  //     for (i in _obj) {
  //       draw(_obj[i]);
  //       u -= 0.5;
  //     }
  //   }

  //   function draw(o) {
  //     if (o.name == "primary") {
  //       g = $.createRadialGradient(o.x, o.y, 0, o.x, o.y, o.rad);
  //       g.addColorStop(0, "hsla(255,255%,255%,1)");
  //       g.addColorStop(1, "hsla(0, 95%, 35%, 1)");
  //       $.beginPath();
  //       $.fillStyle = g;
  //       $.arc(o.x, o.y, o.rad * o.sc * _sc, 0, Math.PI * 2, false);
  //       $.closePath();
  //       $.fill();
  //     }
  //     if (o.name == "orbital") {
  //       g = $.createRadialGradient(o.x, o.y, 0, o.x, o.y, o.rad);
  //       g.addColorStop(0, "hsla(255,255%,255%,1)");
  //       g.addColorStop(1, "hsla(" + (u % 200) + ", 90%, 50%, 1)");
  //       $.beginPath();
  //       $.fillStyle = g;
  //       $.arc(o.x, o.y, o.rad * o.sc * _sc, 0, Math.PI * 2, false);
  //       $.closePath();
  //       $.fill();
  //     } else {
  //       $.fillStyle = o.col;
  //       $.beginPath();
  //       $.arc(o.x, o.y, o.rad * o.sc * _sc, 0, Math.PI * 2, false);
  //       $.closePath();
  //       $.fill();
  //     }
  //   }

  //   function calc() {
  //     _obj.sort(n_sort);

  //     var dx = msY / 60 - _rotY;
  //     var dy = msX / 60 - _rotX;
  //     _rotY += dx / 10;
  //     _rotX += dy / 10;

  //     _obj[0].x = window.innerWidth / 2 + dx;
  //     _obj[0].y = window.innerHeight / 2 + dy;

  //     for (var i in _obj) {
  //       _obj[i].rotX += _obj[i].spX * _sp;
  //       _obj[i].rotY += _obj[i].spY * _sp;

  //       if (_obj[i].name != "primary") {
  //         _obj[i].z =
  //           _obj[i].prime.z -
  //           Math.sin(_rotY + _obj[i].rotY) *
  //             _obj[i].dist *
  //             Math.sin(_rotX + _obj[i].rotX + Math.PI / 2) *
  //             _sc;
  //         _obj[i].sc = 1 + _obj[i].z / 450;

  //         _obj[i].x =
  //           _obj[i].prime.x +
  //           Math.sin(_rotX + _obj[i].rotX) *
  //             (_obj[i].dist + _obj[i].prime.rad) *
  //             _obj[i].sc *
  //             _sc;
  //         _obj[i].y =
  //           _obj[i].prime.y +
  //           Math.cos(_rotX + _obj[i].rotX) *
  //             (_obj[i].dist + _obj[i].prime.rad) *
  //             Math.cos(_rotY + _obj[i].rotY) *
  //             _obj[i].sc *
  //             _sc;
  //       } else {
  //         _obj[i].sc = 1 + _obj[i].z / 50000;
  //       }
  //     }
  //     _obj.sort(z_sort);
  //   }

  //   function n() {
  //     return "" + this.n;
  //   }

  //   function z() {
  //     return "" + this.z;
  //   }

  //   function n_sort(a, b) {
  //     return a.n - b.n;
  //   }

  //   function z_sort(a, b) {
  //     return a.z - b.z;
  //   }

  //   window.addEventListener(
  //     "resize",
  //     function () {
  //       c.width = window.innerWidth;
  //       c.height = window.innerHeight;
  //     },
  //     false
  //   );

  //   window.requestAnimFrame = (function () {
  //     return (
  //       window.requestAnimationFrame ||
  //       window.webkitRequestAnimationFrame ||
  //       window.mozRequestAnimationFrame ||
  //       window.oRequestAnimationFrame ||
  //       window.msRequestAnimationFrame ||
  //       function (callback) {
  //         window.setTimeout(callback, 1000 / 60);
  //       }
  //     );
  //   })();

  //   run();

  //   function run() {
  //     window.requestAnimFrame(run);
  //     orbit();
  //   }
  // };

  // const bgGraphics5 = () => {
  //   // const canvas = document.getElementById("canvas1");
  //   const canvas = document.getElementById("page-bg-canvas");

  //   const innerWidth = window.innerWidth;
  //   const innerHeight = window.innerHeight;

  //   const ctx = canvas.getContext("2d"); // CTX MEANS CONTEXT
  //   ctx.canvas.width = window.innerWidth;
  //   ctx.canvas.height = window.innerHeight;
  //   let particleArray;

  //   // get mouse mouse position ///////////////////////////////
  //   let mouse = {
  //     x: null,
  //     y: null,
  //     radius: (canvas.height / 100) * (canvas.width / 100),
  //   };
  //   window.addEventListener("mousemove", function (event) {
  //     mouse.x = event.x;
  //     mouse.y = event.y;
  //   });

  //   // create Particle
  //   class Particle {
  //     constructor(x, y, directionX, directionY, size, color) {
  //       this.x = x;
  //       this.y = y;
  //       this.directionX = directionX;
  //       this.directionY = directionY;
  //       this.size = size;
  //       this.color = color;
  //       this.speedX = this.directionX;
  //       this.speedY = this.directionY;
  //     }
  //     // create method to draw individual particle
  //     draw() {
  //       ctx.beginPath();
  //       ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2, false);

  //       ctx.fillStyle = "black";
  //       ctx.fill();
  //     }

  //     // check particle position, check mouse position, move the paticle, draw the particle
  //     update() {
  //       // check if particle is still within canvas
  //       if (this.x > canvas.width || this.x < 0) {
  //         this.directionX = -this.directionX;
  //         this.speedX = this.directionX;
  //       }
  //       if (this.y + this.size > canvas.height || this.y - this.size < 0) {
  //         this.directionY = -this.directionY;
  //         this.speedY = this.directionY;
  //       }
  //       // check mouse position/particle position - collision detection
  //       let dx = mouse.x - this.x;
  //       let dy = mouse.y - this.y;
  //       let distance = Math.sqrt(dx * dx + dy * dy);
  //       if (distance < mouse.radius + this.size) {
  //         if (mouse.x < this.x && this.x < canvas.width - this.size * 10) {
  //           this.x += 10;
  //         }
  //         if (mouse.x > this.x && this.x > this.size * 10) {
  //           this.x -= 10;
  //         }
  //         if (mouse.y < this.y && this.y < canvas.height - this.size * 10) {
  //           this.y += 10;
  //         }
  //         if (mouse.y > this.y && this.y > this.size * 10) {
  //           this.y -= 10;
  //         }
  //       }
  //       // move particle
  //       this.x += this.directionX;
  //       this.y += this.directionY;
  //       // call draw method
  //       this.draw();
  //     }
  //   }

  //   // check if particles are close enough to draw line between them
  //   function connect() {
  //     let opacityValue = 1;
  //     for (let a = 0; a < particleArray.length; a++) {
  //       for (let b = a; b < particleArray.length; b++) {
  //         let distance =
  //           (particleArray[a].x - particleArray[b].x) *
  //             (particleArray[a].x - particleArray[b].x) +
  //           (particleArray[a].y - particleArray[b].y) *
  //             (particleArray[a].y - particleArray[b].y);
  //         if (distance < (canvas.width / 7) * (canvas.height / 7)) {
  //           opacityValue = 1 - distance / 10000;
  //           ctx.strokeStyle = "rgba(0,0,0," + opacityValue + ")";
  //           ctx.beginPath();
  //           ctx.lineWidth = 5;
  //           ctx.moveTo(particleArray[a].x, particleArray[a].y);
  //           ctx.lineTo(particleArray[b].x, particleArray[b].y);
  //           ctx.stroke();
  //         }
  //       }
  //     }
  //   }

  //   // create particle array
  //   function init() {
  //     particleArray = [];
  //     let numberOfParticles = (canvas.height * canvas.width) / 6000;
  //     for (let i = 0; i < numberOfParticles; i++) {
  //       let size = Math.random() * 10 + 1;
  //       let x = Math.random() * (innerWidth - size * 2 - size * 2) + size * 2;
  //       let y = Math.random() * (innerHeight - size * 2 - size * 2) + size * 2;
  //       let directionX = Math.random() * 2 - 1;
  //       let directionY = Math.random() * 2 - 1;

  //       let color = "gold";
  //       particleArray.push(
  //         new Particle(x, y, directionX, directionY, size, color)
  //       );
  //     }
  //   }

  //   // create animation loop
  //   function animate() {
  //     requestAnimationFrame(animate);
  //     ctx.clearRect(0, 0, innerWidth, innerHeight);

  //     for (let i = 0; i < particleArray.length; i++) {
  //       particleArray[i].update();
  //     }
  //     connect();
  //   }
  //   init();
  //   animate();
  // };

  const bgGraphics = bgGraphics2;

  React.useEffect(() => {
    bgGraphics();
  }, [bgGraphics]);

  return (
    <React.Fragment>
      <CssBaseline />
      <canvas id="page-bg-canvas" className="page-bg-canvas" />
      <Container maxWidth="sm">
        <Paper
          elevation={3}
          sx={{
            paddingLeft: 4,
            paddingRight: 4,
            minHeight: "100vh",
            backgroundColor: BG_COLOR2,
          }}
          alignItems="center"
        >
          <ContactUsV3 onClickSubmit={onClickSubmit} />
        </Paper>
      </Container>
    </React.Fragment>
  );
}
